import React from 'react';
import CallToAction from '../../../molecules/CallToAction/CallToAction';
import Section from '../../../molecules/Section/Section';
import { colors } from '../../../styles/atoms/colors';
import OptimizedImage from '../../../utils/OptimizedImage';
import {
  Container,
  Image,
  Text,
} from '../styles/MobileAppConversionPanel.styled';

const MobileAppConversionPanel = ({
  id,
  heading,
  subhead,
  conversionPanelImage,
  conversionPanelCallToAction,
}) => {
  return (
    <Section id={id} bgColor={colors.base.heroSurface}>
      <Container>
        <Text>
          {heading && <p className="heading">{heading}</p>}
          {subhead && <p className="subhead">{subhead}</p>}
          {conversionPanelCallToAction &&
            conversionPanelCallToAction.length > 0 && (
              <div className="cta-wrapper">
                {conversionPanelCallToAction.map(cta => (
                  <CallToAction
                    key={cta.id}
                    variant={cta?.variant}
                    size={cta?.size}
                    link={cta?.url}
                  />
                ))}
              </div>
            )}
        </Text>
        <Image>
          <OptimizedImage
            image={conversionPanelImage?.image?.gatsbyImageData}
            alt={conversionPanelImage?.image?.alt}
            title={conversionPanelImage?.image?.title}
          />
        </Image>
      </Container>
    </Section>
  );
};

export default MobileAppConversionPanel;
